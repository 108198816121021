<template>
    <div>
      <Divider dashed ><span class="divider-text">辅助工具</span></Divider>
        <Row>
            <i-col span="24">
                <Button type="success" class="m-r-5" size="small" @click.native="ExportOrderExcel">下载订单详情</Button>
            </i-col>
        </Row>

        <export-order-excel ref="exportOrderExcel" :hiddenOrderPoint="hiddenOrderPoint" :orderInfo="orderInfo"></export-order-excel>
    </div>
</template>

<script>
import ExportOrderExcel from '@/components/order/common/ExportOrderExcel'

export default {
  props: {
    orderBean: {
      type: Object,
      required: true
    }
  },
  components: {
    ExportOrderExcel
  },
  data () {
    return {
      orderInfo: this.orderBean,
      hiddenOrderPoint: this.$store.getters.token.publisherSetting.orderHiddenPoint
    }
  },
  methods: {
    ExportOrderExcel () {
      this.$refs.exportOrderExcel.init()
    }
  },
  watch: {
    orderBean (val) {
      this.orderInfo = this.orderBean
    }
  }
}
</script>
