import request from '@/utils/requestV2'
const qs = require('qs')

// 获取订单相似度
export function similarity (data) {
  return request({
    url: '/ooh-recommend/v1/recommend/similarity',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 设置推荐方案为应用状态
export function applyPlan (data) {
  return request({
    url: '/ooh-recommend/v1/plan/applyplan',
    method: 'post',
    data: qs.stringify(data)
  })
}
