<template>
    <Modal v-model="modalShow" width="800" title="导出订单方案" footer-hide>
      <h3>历史订单方案：</h3>
      <Table  stripe  size="small" :columns="orderExcelColumns" :data="orderExcelList"></Table>
      <div class="paging_style">
          <Page size="small" :total="total" :page-size="query.pageSize" :current="query.pageNumber" show-total show-elevator @on-change="handlePageNumberChange"></Page>
      </div>

      <Row class="m-t-10 m-b-10">
        <i-col flex="80px"><h3>方案类型：</h3></i-col>
        <i-col flex="120px">
          <RadioGroup v-model="selectedDownloadType">
            <Radio label="1">Excel</Radio>
            <Radio label="2">PPT</Radio>
          </RadioGroup>
        </i-col>
        <i-col flex="auto">
          <Button type="success" size="small" @click="handleSubmit">开始生成</Button>
        </i-col>
      </Row>
    </Modal>
</template>

<script>
import { getOrderExcelList, getOrderExcel, downloadExcel } from '@/api/order/order'

export default {
  props: {
    orderInfo: {
      type: Object,
      required: true
    },
    hiddenOrderPoint: Boolean
  },
  data () {
    return {
      modalShow: false,
      selectedDownloadType: '1',

      orderExcelColumns: [
        { title: '生成日期', key: 'createTime' },
        {
          title: '文件类型',
          render: (h, params) => {
            return h('span', params.row.type === 1 ? 'Excel' : 'PPT')
          }
        },
        {
          title: '进度',
          width: 80,
          render: (h, params) => {
            if (params.row.status === -1) { // 生成失败
              return h('Icon', { props: { type: 'md-close-circle', color: '#ed4014', size: '12' } })
            } else if (params.row.status === 0) { // 正在处理
              const processName = 'exportOrderExcel' + params.row.id
              this.registerProcess(processName)

              return h('div', [
                h('span', { attrs: { class: processName } }, '1'),
                h('span', '%')
              ])
            } else {
              return h('Icon', { props: { type: 'md-checkmark', color: '#44BD32', size: '16' } })
            }
          }
        },
        {
          title: '操作',
          width: 80,
          render: (h, params) => {
            if (params.row.status === 1) {
              return h('a',
                {
                  on: {
                    click: () => {
                      this.downloadFile(params.row)
                    }
                  }

                }, '下载'
              )
            }
          }
        }
      ],
      orderExcelList: [],
      total: 0,
      query: {
        pageSize: 5,
        pageNumber: 1,
        orderId: 0
      },
      loadExcelEnd: false
    }
  },
  methods: {
    /**
     * 初始化获取导出Excel配置
     */
    init () {
      this.modalShow = true
      this.loadExcelEnd = false

      this.loadExcelList()
    },
    loadExcelList () {
      this.query.orderId = this.orderInfo.id

      getOrderExcelList(this.query).then(res => {
        this.orderExcelList = res.list
        this.total = res.totalRow
      }).catch(() => {
        this.loadExcelEnd = true
      })
    },
    handlePageNumberChange (currentPage) {
      this.query.pageNumber = currentPage
      this.loadExcelList()
    },
    handleSubmit () {
      const postData = {
        orderId: this.orderInfo.id,
        type: this.selectedDownloadType
      }
      getOrderExcel(postData).then(res => {
        this.loadExcelList()
      })
    },
    downloadFile (param) {
      downloadExcel({ planId: param.id }).then(res => {
        window.open(res)
      })
    },
    registerProcess (name) {
      if (this.loadExcelEnd) { return }

      const processControl = window.document.getElementsByClassName(name)

      if (processControl.length === 0) {
        setTimeout(() => {
          this.registerProcess(name)
        }, 1000)
      } else if (processControl[0].innerText !== '99') {
        processControl[0].innerText = parseInt(processControl[0].innerText) + 1
        setTimeout(() => {
          this.registerProcess(name)
        }, 1000)
      } else {
        this.loadExcelEnd = true
        this.loadExcelList()
      }
    }
  }
}
</script>
