<template>
    <div>
        <baseInfo :orderBean="orderBean"/>
        <tool :orderBean="orderBean"/>
        <orderFeature/>
    </div>
</template>

<script>
import tool from '../components/Tool'
import baseInfo from '../components/BaseInfo'
import orderFeature from '../components/OrderFeature'

export default {
  components: {
    baseInfo, orderFeature, tool
  },
  computed: {
    orderBean () {
      return this.$store.state.adx.orderBean
    }
  }
}
</script>
